/* eslint-disable no-alert */
/* eslint-disable no-nested-ternary */
import React, { useState, useEffect, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Base64 } from 'js-base64'
import { CircularProgress } from '@material-ui/core'
import { FiCheck, FiClock } from 'react-icons/fi'
import Switch from 'react-switch'
import usePersistedState from '../../hooks/usePersistedState'
import api from '../../services/api'
import Header from '../../components/Header'
import Button from '../../components/Button'
import {
  ConfigData,
  UserData,
  UserDetails,
  Participant,
  ErroProps,
} from '../../utils/interfaces'
import { Container, Content, BtnVoltar, BtnModal } from './styles'
import ModalBox from '../../components/Modal'

const Conclusion: React.FC = () => {
  const [configData] = usePersistedState<ConfigData>(
    'configData',
    {} as ConfigData,
  )
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails] = usePersistedState<UserDetails>(
    'userDetails',
    {} as UserDetails,
  )
  const [selectedReceive] = usePersistedState<'PD' | 'PS' | null>(
    'receiveTypeSelected',
    'PD',
  )
  const [participants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [, setErroProps] = usePersistedState<ErroProps>(
    'erroProps',
    {} as ErroProps,
  )
  const [aguarde, setAguarde] = useState(false)
  const [timer, setTimer] = useState(0)
  const [reSend, setReSend] = useState(true)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [warningText, setWarningText] = useState('')
  const [acceptTerms, setAcceptTerms] = usePersistedState('acceptTerms', false)

  const toggleAcceptTerms = useCallback(() => {
    setAcceptTerms(!acceptTerms)
  }, [acceptTerms, setAcceptTerms])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])
  const history = useHistory()

  const handleConfirmaAdesao = useCallback(async () => {
    setAguarde(true)
    const parametros = Base64.encode(
      `{
        "token": "${configData.token}",
        "versao": "${configData.tipo}",
        "plano": "${configData.plano}",
        "nome": "${userData.name.toLowerCase()}",
        "cpf": "${userData.cpf.replaceAll('.', '').replace('-', '')}",
        "nascimento": "${userData.birthdate}",
        "admissao":"${userData.admission}",
        "email": "${userData.email}",
        "telefone": "55${userData.phone
          .replace('(', '')
          .replace(') ', '')
          .replace('-', '')}",
        "salario": "${userDetails.salario}",
        "valor": "${userDetails.contribuicaoSuplementar
          .toString()
          .replace('.', ',')}",
        "pctCtbBasica": "${userDetails.pctContribuicaoSuplementar
          .toString()
          .replace('.', ',')}",
        "valorPatroc": "${userDetails.contribuicaoSuplementarPatrocinadora
          .toString()
          .replace('.', ',')}",
        "pctCtbPatroc": "${userDetails.pctContribuicaoSuplementar
          .toString()
          .replace('.', ',')}",
        "valorsuplementar": "${userDetails.contribuicaoAdicional
          .toString()
          .replace('.', ',')}",
        "pctCtbSuplementar": "${userDetails.pctContribuicaoAdicional
          .toString()
          .replace('.', ',')}",
        "idade_apos":"${userDetails.age}",
        "tempo_contribuicao": "${userDetails.years}",
        "politicamenteexposto": "${userDetails.ppe}",
        "usperson": "${userDetails.usperson}",
        "forma_resgate": "${selectedReceive}",
        "beneficiarios": [${participants.map(
          participant => `
          {"nome": "${participant.data.name.toLowerCase()}",
          "nascimento": "${participant.data.birthdate}",
          "parentesco":"${participant.details.grauParentesco}",
          "tipo":"${participant.details.tipoBen}",
          "cpf": "${participant.data.cpf.replaceAll('.', '').replace('-', '')}",
          "fator":"${
            participant.details.proporcao === undefined
              ? '0'
              : participant.details.proporcao.toString().replace('.', ',')
          }"}`,
        )}]
        }`,
    )

    const parametrosFinal = Base64.encode(parametros)

    // history.push('/end')

    await api
      .post(`wsAdesao.rule?sys=ADZ&Entrada=${parametrosFinal}`)
      .then(() => {
        localStorage.removeItem('@AdsPrevPepsico:configData')
        localStorage.removeItem('@AdsPrevPepsico:participantsGroup')
        localStorage.removeItem('@AdsPrevPepsico:PercentualValuePercent')
        localStorage.removeItem('@AdsPrevPepsico:receiveTypeSelected')
        localStorage.removeItem('@AdsPrevPepsico:TimeValueYears')
        localStorage.removeItem('@AdsPrevPepsico:totalBalance')
        localStorage.removeItem('@AdsPrevPepsico:userData')
        localStorage.removeItem('@AdsPrevPepsico:userDetails')
        localStorage.removeItem('@AdsPrevPepsico:erroProps')
        localStorage.removeItem('@AdsPrevPepsico:acceptTerms')
        localStorage.removeItem('@AdsPrevPepsico:RendaFixaValue')
        localStorage.removeItem('@AdsPrevPepsico:flagEdit')

        history.push('/end')
      })
      .catch(res => {
        if (res.message === 'Request failed with status code 401') {
          alert('Tempo de sessão expirado')
          history.push('/register')
        } else if (res.message === 'Request failed with status code 406') {
          setWarningText(
            'Adesão não autorizada. Verifique se você já possui cadastro no plano.',
          )
          setIsModalOpen(true)
          setReSend(false)
          setTimer(20)
          setAguarde(false)
        } else if (res.message === 'Network Error') {
          setErroProps({
            title: res.message,
            description: 'Falha na conexão como servidor',
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 500') {
          setErroProps({
            title: 'Erro interno no servidor',
            description: res.message,
          })
          history.push('/erro')
        } else if (res.message === 'Request failed with status code 408') {
          alert('Tempo de sessão expirado')
          history.push('/register')
        } else {
          setReSend(false)
          setTimer(20)
          setAguarde(false)
          setWarningText('Ops, algo deu errado. Tente novamente mais tarde.')
          setIsModalOpen(true)
        }
      })
  }, [
    configData,
    history,
    participants,
    selectedReceive,
    setErroProps,
    userData,
    userDetails,
  ])

  useEffect(() => {
    const countTimer = (): void => {
      if (timer > 1) {
        setTimer(timer - 1)
      } else if (timer === 1) {
        setTimer(timer - 1)
        setReSend(true)
      }
    }

    const interval = setInterval(countTimer, 1000)

    return () => clearInterval(interval)
  }, [timer])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Confirmação de Adesão</strong>
          <span>
            Eu <b>{userData.name}</b>, inscrito no CPF <b>{userData.cpf}</b>,
            declaro que ao clicar em <b>Confirmar Adesão</b>, confirmo minha
            adesão ao <b>Plano de Benefícios Pepsico</b> e que as informações
            prestadas são verídicas.
          </span>

          <div>
            <small>
              Aceito os{' '}
              <a
                href="https://staging-prev-pepsico.webflow.io/legal/termos-de-uso"
                target="_blank"
                rel="noopener noreferrer"
              >
                Termos de Uso
              </a>
            </small>
            <Switch
              onChange={toggleAcceptTerms}
              checked={acceptTerms}
              checkedIcon={false}
              uncheckedIcon={false}
              height={20}
              width={40}
              handleDiameter={16}
              offColor="#FBADB4"
              offHandleColor="#fff"
              onHandleColor="#fff"
              onColor="#31D19E"
            />
          </div>
        </Content>
        <Button
          type="button"
          color="orange"
          width="large"
          disabled={!acceptTerms || !reSend || aguarde}
          onClick={handleConfirmaAdesao}
        >
          {!aguarde ? (
            !reSend ? (
              <>
                <FiClock size={45} />
                <span>
                  Tente novamente em {timer < 10 ? `0${timer}` : timer} segundos
                </span>
              </>
            ) : (
              <>
                <FiCheck size={45} />
                <span>Confirmar adesão</span>
              </>
            )
          ) : (
            <>
              <CircularProgress color="inherit" />
              <span>Aguarde</span>
            </>
          )}
        </Button>
        <BtnVoltar type="button" onClick={() => history.push('/resume')}>
          &lt; Anterior
        </BtnVoltar>

        <ModalBox isOpen={isModalOpen} onRequestClose={handleCloseModal}>
          <p>{warningText}</p>
          <BtnModal isActive onClick={() => history.push('/')}>
            Ok
          </BtnModal>
        </ModalBox>
      </Container>
    </>
  )
}

export default Conclusion
