/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { FormHandles } from '@unform/core'
import { Form } from '@unform/web'
import Modal from 'react-modal'
import moment from 'moment'
import * as Yup from 'yup'

import { FiUser, FiCheck, FiX, FiPercent } from 'react-icons/fi'
import { MdSecurity } from 'react-icons/md'
import { AiOutlineQuestionCircle } from 'react-icons/ai'

import { BiCake } from 'react-icons/bi'
import getValidationErrors from '../../utils/getValidationErrors'
import usePersistedState from '../../hooks/usePersistedState'
import validaCPF from '../../utils/validaCPF'

import InputSelect from '../../components/InputSelect'
import InputHidden from '../../components/InputHidden'
import Header from '../../components/Header'
import Button from '../../components/Button'
import Input from '../../components/Input'

import {
  Container,
  Content,
  RadioButton,
  BtnVoltar,
  BtnContato,
  Line,
} from './styles'
import {
  UserData,
  Participant,
  ParticipantDetails,
} from '../../utils/interfaces'
import calculaIdade from '../../utils/calculaIdade'

const NovoParticipante: React.FC = () => {
  const [userData] = usePersistedState<UserData>('userData', {} as UserData)
  const [userDetails] = usePersistedState<ParticipantDetails>(
    'userDetails',
    {} as ParticipantDetails,
  )

  const [participants, setParticipants] = usePersistedState<Participant[]>(
    'participantsGroup',
    [],
  )
  const [vlrProporcao, setVlrProporcao] = useState(1)
  const [, setSaveAndAddNew] = useState(false)
  const [tipoBenef, setTipoBenef] = useState('2')
  const [isModalOpen, setIsModalOpen] = useState(false)

  const [thisParticipantData] = useState<UserData>({} as UserData)
  const [thisParticipantDetails] = useState<ParticipantDetails>(
    {} as ParticipantDetails,
  )
  const [thisParticipant, setThisParticipant] = useState<Participant>(
    {} as Participant,
  )

  const [grauParent, setGrauParent] = useState({
    label: userDetails.dcrGrauParentesco,
    value: userDetails.grauParentesco,
  })

  const formRef = useRef<FormHandles>(null)
  const history = useHistory()
  const location = useLocation()

  // const [dtNasc, setDtNasc] = useState('')

  const handleAddNovo = useCallback(() => {
    setSaveAndAddNew(true)
    const field = formRef.current?.getFieldRef('addNew')
    field.value = true
    formRef.current?.submitForm()
  }, [])

  const handleJustSave = useCallback(() => {
    setSaveAndAddNew(false)
    const field = formRef.current?.getFieldRef('addNew')
    field.value = false
    formRef.current?.submitForm()
  }, [])

  const handleChangeGrauParentesco = useCallback(e => {
    const t = e
    setGrauParent(t)
  }, [])

  const handleOpenModal = useCallback(() => {
    setIsModalOpen(true)
  }, [])

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const handleSubmit = useCallback(
    async formData => {
      try {
        formRef.current?.setErrors({})
        const schema = Yup.object().shape({
          name: Yup.string()
            .required('Seu nome é obrigatório.')
            .matches(/\s/g, 'Digite o nome completo')
            .min(3, 'Digite o nome completo'),
          birthdate: Yup.string()
            .required('Data de nascimento é obrigatória.')
            .min(10, 'Data de nascimento deve seguir o formato dd/mm/aaaa.')
            .test(
              '',
              'A data de nascimento não pode ser maior que hoje.',
              () =>
                moment() >
                  moment(formData.birthdate.split('/').reverse().join('-')) ||
                formData.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                moment(
                  formData.birthdate.split('/').reverse().join('-'),
                ).isValid() || formData.birthdate === '',
            )
            .test(
              '',
              'Data de nascimento inválida',
              () =>
                calculaIdade(
                  formData.birthdate.split('/').reverse().join('-'),
                ) <= 115 || formData.birthdate === '',
            ),
          cpf: Yup.string()
            .required('CPF é obrigatório.')
            .test('', 'CPF já utilizado em outro cadastro', function v() {
              const t2 =
                (participants.filter(
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  (participant, idx) => participant.data.cpf === formData.cpf,
                ).length <= 0 &&
                  formData.cpf !== userData.cpf) ||
                formData.cpf === ''
              return t2
            })
            .test('', 'CPF inválido', function t() {
              const teste =
                validaCPF(formData.cpf.replaceAll('.', '').replace('-', '')) ||
                formData.cpf === ''
              return teste
            }),
          grauParentesco: Yup.string().required('Campo obrigatório'),
          proporcao: Yup.string().test(
            '',
            'Campo obrigatório',
            () =>
              (tipoBenef === '1' && formData.proporcao > 0) ||
              tipoBenef === '2',
          ),
        })

        await schema.validate(formData, { abortEarly: false })

        const aa = formData.birthdate
        const dia = aa.split('/')[0]
        const mes = aa.split('/')[1]
        const ano = aa.split('/')[2]
        const dataForm = `${ano}-${`0${mes}`.slice(-2)}-${`0${dia}`.slice(-2)}`

        setThisParticipant({
          ...thisParticipant,
          data: {
            ...thisParticipantData,
            name: formData.name,
            cpf: formData.cpf,
            birthdate: formData.birthdate === '' ? '' : dataForm,
          },
          details: {
            ...thisParticipantDetails,
            tipoBen: tipoBenef,
            grauParentesco: grauParent.value,
            dcrGrauParentesco: grauParent.label,
            proporcao: tipoBenef === '1' ? vlrProporcao : 0,
          },
        })

        setParticipants([
          ...participants,
          {
            ...thisParticipant,
            data: {
              ...thisParticipantData,
              name: formData.name,
              cpf: formData.cpf,
              birthdate: formData.birthdate === '' ? '' : dataForm,
            },
            details: {
              ...thisParticipantDetails,
              tipoBen: tipoBenef,
              grauParentesco: grauParent.value,
              dcrGrauParentesco: grauParent.label,
              proporcao: tipoBenef === '1' ? vlrProporcao : 0,
            },
          },
        ])
        if (formData.addNew === 'true') {
          history.push(`${location.pathname}`)
          formRef.current?.reset()
        } else {
          history.push('/participants-list')
        }
      } catch (err) {
        formRef.current?.setErrors(getValidationErrors(err))
      }
    },
    [
      grauParent,
      history,
      location.pathname,
      participants,
      setParticipants,
      thisParticipant,
      thisParticipantData,
      thisParticipantDetails,
      tipoBenef,
      userData,
      vlrProporcao,
    ],
  )

  const arr = participants.map(participant => participant.details.proporcao)
  let soma = 0
  for (let i = 0; i < arr.length; i += 1) {
    soma += arr[i]
  }

  const handleValidaProporcao = useCallback((prop, s) => {
    if (prop < 1 || prop > s) {
      const msg = `A proporção deve ser maior que 1 e menor que ${s}`
      // eslint-disable-next-line no-alert
      alert(msg)
    } else {
      setVlrProporcao(prop)
    }
  }, [])

  return (
    <>
      <Header />
      <Container>
        <Content>
          <strong>Adicione um beneficiário: </strong>
          <Form
            ref={formRef}
            onSubmit={handleSubmit}
            initialData={{
              name: thisParticipantData.name,
              cpf: thisParticipantData.cpf,
              tipoBen: thisParticipantDetails.tipoBen,
              birthdate:
                thisParticipantData.birthdate === undefined
                  ? ''
                  : thisParticipantData.birthdate
                      .split('-')
                      .reverse()
                      .join('/'),
              grauParentesco: grauParent.value,
              dcrGrauParentesco: grauParent.label,
            }}
          >
            <InputHidden name="addNew" type="hidden" />
            <InputHidden name="contribution" type="hidden" />
            <InputHidden name="years" type="hidden" />

            <RadioButton>
              <AiOutlineQuestionCircle onClick={handleOpenModal} />
              <label>Tipo de Beneficiário</label>
              <div>
                <BtnContato
                  type="button"
                  isActive={tipoBenef === '2'}
                  onClick={() => {
                    setTipoBenef('2')
                    setGrauParent({ label: '', value: '' })
                  }}
                >
                  Beneficiário legal
                </BtnContato>
                <BtnContato
                  type="button"
                  isActive={tipoBenef === '1'}
                  onClick={() => {
                    setTipoBenef('1')
                    setGrauParent({ label: '', value: '' })
                  }}
                >
                  Beneficiário indicado
                </BtnContato>
              </div>
            </RadioButton>

            {tipoBenef === '2' ? (
              <InputSelect
                name="grauParentesco"
                value={grauParent}
                options={[
                  { label: 'Conjuge', value: '1' },
                  { label: 'Companheiro(a)', value: '2' },
                  { label: 'Filho(a)', value: '3' },
                  { label: 'Enteado(a)', value: '8' },
                ]}
                placeholder="Tipo de Vínculo"
                onChange={e => handleChangeGrauParentesco(e)}
              />
            ) : (
              <InputSelect
                name="grauParentesco"
                value={grauParent}
                options={[
                  { label: 'Pai / Mãe', value: '5' },
                  { label: 'Irmão(a)', value: '6' },
                  { label: 'Outros', value: '0' },
                ]}
                placeholder="Tipo de Vínculo"
                onChange={e => handleChangeGrauParentesco(e)}
              />
            )}
            <Input placeholder="Nome completo" name="name" icon={FiUser} />
            <Input
              placeholder="CPF"
              name="cpf"
              icon={MdSecurity}
              type="tel"
              mask="cpf"
              required
            />
            <Input
              icon={BiCake}
              name="birthdate"
              placeholder="Data de nascimento"
              maxLength={10}
              mask="date"
            />
            {tipoBenef === '1' ? (
              <Input
                placeholder="Proporção"
                icon={FiPercent}
                value={vlrProporcao}
                name="proporcao"
                type="number"
                min="1"
                max={soma === 0 ? 100 : 100 - soma}
                onChange={e => {
                  handleValidaProporcao(
                    e.target.valueAsNumber,
                    soma === 0 ? 100 : 100 - soma,
                  )
                }}
              />
            ) : (
              <></>
            )}
          </Form>
          <Button type="button" fontSize="small" onClick={handleAddNovo}>
            Adicionar mais um beneficiário
          </Button>
        </Content>

        <Button
          type="button"
          fontSize="normal"
          color="orange"
          onClick={handleJustSave}
        >
          <FiCheck size={45} />
          <span>Adicionar este!</span>
        </Button>

        {participants.length > 0 ? (
          <Button
            type="button"
            fontSize="normal"
            color="white"
            onClick={() => history.push('/participants-list')}
          >
            Ver beneficiários
          </Button>
        ) : (
          <Button
            type="button"
            fontSize="normal"
            color="pink"
            onClick={() => history.push('/resume')}
          >
            <span>Não tenho beneficiários</span>
          </Button>
        )}

        <BtnVoltar type="button" onClick={() => history.push('/fatca')}>
          &lt; Anterior
        </BtnVoltar>

        <Modal
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
          overlayClassName="react-modal-overlay"
          className="react-modal-content"
        >
          <FiX onClick={handleCloseModal} />
          <h3>Qual a diferença entre os tipos de beneficiários?</h3>
          <div>
            <strong>Beneficiário Legal</strong>
            <ul>
              <li>
                Cônjuge, companheiro (as) e filhos, incluindo enteado e adotado
                (a) legalmente. O percentual a ser recebido pelos beneficiários
                legais será dividido em partes iguais.{' '}
              </li>
            </ul>
          </div>

          <Line />

          <div>
            <strong>Beneficiário Indicado</strong>
            <ul>
              <li>
                Na ausência de beneficiários, é possível indicação e determinar
                a proporção que caberá a cada indicado.
              </li>
            </ul>
          </div>
        </Modal>
      </Container>
    </>
  )
}

export default NovoParticipante
